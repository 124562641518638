import React, { useState } from 'react';
import './tabsmenu.css';
import useFetch from '../../hooks/Usefetch';

const Tabmenu = () => {
  const [activeTab, setActiveTab] = useState('Tab 1');
  const { data, loading, error } = useFetch("/tabs-contents?populate=*");
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading content...</p>;

  // Ensure data is not null or empty
  if (!data || data.length === 0) {
    return <p>No content available</p>;
  }
  const item1 = data[0];
  const item2 = data[1];
  const item3 = data[2];
  const item4 = data[3];

  const desc1 = item1.attributes?.desc || "No Title";
  const Tab1image = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img1?.data?.attributes?.url;
  const Tab1image2 = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img2?.data?.attributes?.url;
  const Tab1image3 = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img3?.data?.attributes?.url;
  const Tab1image4 = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img4?.data?.attributes?.url;
  const Tab1image5 = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img5?.data?.attributes?.url;
  const Tab1image6 = process.env.REACT_APP_UPLOAD_URL + item1.attributes?.img6?.data?.attributes?.url;

  const Tab1images = [Tab1image, Tab1image2, Tab1image3, Tab1image4, Tab1image5, Tab1image6];



  const desc2 = item2.attributes?.desc || "No Title";
  const Tab2image1 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img1?.data?.attributes?.url;
  const Tab2image2 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img2?.data?.attributes?.url;
  const Tab2image3 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img3?.data?.attributes?.url;
  const Tab2image4 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img4?.data?.attributes?.url;
  const Tab2image5 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img5?.data?.attributes?.url;
  const Tab2image6 = process.env.REACT_APP_UPLOAD_URL + item2.attributes?.img6?.data?.attributes?.url;
 
  const Tab2images = [Tab2image1, Tab2image2, Tab2image3, Tab2image4, Tab2image5, Tab2image6];

  const desc3 = item3.attributes?.desc || "No Title";
  const Tab3image1 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img1?.data?.attributes?.url;
  const Tab3image2 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img2?.data?.attributes?.url;
  const Tab3image3 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img3?.data?.attributes?.url;
  const Tab3image4 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img4?.data?.attributes?.url;
  const Tab3image5 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img5?.data?.attributes?.url;
  const Tab3image6 = process.env.REACT_APP_UPLOAD_URL + item3.attributes?.img6?.data?.attributes?.url;
 
  const Tab3images = [Tab3image1, Tab3image2, Tab3image3, Tab3image4, Tab3image5, Tab3image6];

  const desc4 = item4.attributes?.desc || "No Title";
  const Tab4image1 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img1?.data?.attributes?.url;
  const Tab4image2 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img2?.data?.attributes?.url;
  const Tab4image3 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img3?.data?.attributes?.url;
  const Tab4image4 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img4?.data?.attributes?.url;
  const Tab4image5 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img5?.data?.attributes?.url;
  const Tab4image6 = process.env.REACT_APP_UPLOAD_URL + item4.attributes?.img6?.data?.attributes?.url;  
 
  const Tab4images = [Tab4image1, Tab4image2, Tab4image3, Tab4image4, Tab4image5, Tab4image6];

  const handleTabChange = (tab, event) => {
    event.preventDefault();
    setActiveTab(tab);


  };

  return (
    <>
    <div className='tab-sec'>
    <div className='container'>
      <div className='title-wrapper slide0'>
        <h2 className='section-title-2'>What We do</h2>
        <div className='separator'></div>
      </div>
      
      <div id='about-us' className='slide0 w-tabs'>
        <div className='tabs-menu w-tab-menu' role='tablist'>
          <a
            onClick={(e) => handleTabChange('Tab 1', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 1' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-0'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-0'
            aria-selected={activeTab === 'Tab 1'}
            tabIndex='-1'
          >
            <div>management</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 2', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 2' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-1'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-1'
            aria-selected={activeTab === 'Tab 2'}
            tabIndex='-1'
          >
            <div>development</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 3', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 3' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-2'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-2'
            aria-selected={activeTab === 'Tab 3'}
            tabIndex='-1'
          >
            <div>sourcing</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 4', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 4' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-3'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-3'
            aria-selected={activeTab === 'Tab 4'}
          >
            <div>agency</div>
          </a>
        </div>

        <div className='tabs-content w-tab-content'>
          <div
            data-w-tab='Tab 1'
            className={`w-tab-pane ${activeTab === 'Tab 1' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-0'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-0'
          >
            <div className='tab-content'>
              <p>
                {desc1}
              </p>
              {/* <p>
                Be it the right branded product, your own label, or complete advice on your range in the areas
                of indoor/outdoor sports, camping, trekking – we can help you to find the right product quality
                from the right producer, to save costs, and to give you the whole process from the product
                development to the shipment and delivery.
              </p> */}
              <div className='w-layout-grid grid'>
              {Tab1images.map((imageSrc, index) => (
      <a
        key={index}
        className={`img-grid grid-item${index + 1} w-inline-block w-lightbox`}
      >
        <img src={imageSrc} loading='lazy' alt={`Tab 1 Image ${index + 1}`} className='img-lb' />
      </a>
    ))}
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 2'
            className={`w-tab-pane ${activeTab === 'Tab 2' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-1'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-1'
          >
            <div className='tab-content'>
              <p>
              {desc2}
              </p>
              {/* <p> 
                In the end, a good product fulfills the original idea, satisfies the customer’s needs, and of
                course, in the end, also satisfies the seller.
              </p>
              <p>
                We organize this development process and work with our partners to ensure timely procurement.
                Thanks to many years of experience and a good network, we are able to manage the entire process.
                Starting with the first ideas through the sampling and up to the definitive delivery as requested.
              </p> */}
              <div className='w-layout-grid grid'>
              {Tab2images.map((imageSrc, index) => (
      <a
        key={index}
        className={`img-grid grid-item${index + 1} w-inline-block w-lightbox`}
      >
        <img src={imageSrc} loading='lazy' alt={`Tab 1 Image ${index + 1}`} className='img-lb' />
      </a>
    ))}
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 3'
            className={`w-tab-pane ${activeTab === 'Tab 3' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-2'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-2'
          >
            <div className='tab-content'>
              <p>{desc3}</p>
              {/* <p>
                Sourcing for the right manufacturer, the right product, or the right brand is the main part of our
                service. It is worthwhile to carefully examine the possibilities and to find the right partner for
                your own project. With our office in Shenzhen, we are always with the dedicated person in the
                productions and can therefore guarantee that everything runs smoothly.
              </p>
              <p>
                This closeness to the producers is an essential part of quality assurance, sampling, or being able
                to answer questions about production quickly and specifically.
              </p>
              <p>
                Our product sourcing expertise is in the field of indoor/outdoor sports, camping and hiking, water
                sports, snow sports, fitness items, general electronics, machinery and hardware tools, electric
                bike, and electric vehicle industry.
              </p> */}
              <div className='w-layout-grid grid'>
              {Tab3images.map((imageSrc, index) => (
      <a
        key={index}
        className={`img-grid grid-item${index + 1} w-inline-block w-lightbox`}
      >
        <img src={imageSrc} loading='lazy' alt={`Tab 1 Image ${index + 1}`} className='img-lb' />
      </a>
    ))}
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 4'
            className={`w-tab-pane ${activeTab === 'Tab 4' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-3'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-3'
          >
            <div className='tab-content'>
              <p>{desc4}</p>
              {/* <p>
                We also have very good contacts with the leading producers of outdoor sports, camping, and water
                sports products, machinery, tools, and general trading together with the brands that we represent
                in multiple territories partners and resellers, we are able to offer you a branded product as well
                as a house label OEM/ODM complete solution.
              </p> */}
              <div className='w-layout-grid grid'>
              {Tab4images.map((imageSrc, index) => (
      <a
        key={index}
        className={`img-grid grid-item${index + 1} w-inline-block w-lightbox`}
      >
        <img src={imageSrc} loading='lazy' alt={`Tab 1 Image ${index + 1}`} className='img-lb' />
      </a>
    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Tabmenu;
