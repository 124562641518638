// src/components/Faq/Faq.js
import React, { useState } from 'react';
import './Faq.css';
import useFetch from '../../hooks/Usefetch';
const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { data, loading, error } = useFetch("/faqs?populate=*");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading content...</p>;

  // Ensure data is not null or empty
  if (!data || data.length === 0) {
    return <p>No FAQ content available</p>;
  }

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="faq-container">
        <div className="container">
          <div className="title-wrapper slide0">
            <h2 className="section-title-2">FAQ</h2>
            <div className="separator"></div>
          </div>
        </div>
        {data.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <h3>{item.attributes?.Q1}</h3>
              <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>+</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              <p>{item.attributes?.A1}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;