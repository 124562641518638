import React from "react";
import './serviceinfo.css'
import useFetch from "../../hooks/Usefetch";

function Serviceinfo() {
  const { data, loading, error } = useFetch("/serviceinfos?populate=*");

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title">How we source the product?</h2>
          <div className="separator"></div>
        </div>
        <div className="row">
          {data.map((item, index) => (
            <div key={index} className="col-lg-4 col-xs-12">
              <div className="servhol">
                <div className="serholimg">
                  {/* {item.attributes?.img && (
                    <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes.img.data.attributes.url} alt={item.attributes.title} />
                  )} */}
                </div>
                <div className="colmeents">
                  <h3>{item.attributes?.title || "No Title"}</h3>
                  <p>{item.attributes?.desc || "No Description"}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Serviceinfo;