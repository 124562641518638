import React from 'react';
import './Testimonial.css';
import useFetch from "../../hooks/Usefetch";
const testimonials = [
  {
    name: 'John Doe',
    position: 'CEO of Tech Innovations ',
    text: 'Soursee made our sourcing process a breeze. Their attention to detail and smooth management helped our international expansion go off without a hitch. Highly recommended!',
    image: '/Assets/testimonial/pic.jpg'
  },
  {
    name: 'Sarah Smith',
    position: 'Product Manager at EcoGoods',
    text: 'Partnering with Soursee was a game-changer. Their expertise in finding suppliers and managing quality control meant we got top-notch products at great prices.',
    image: '/Assets/testimonial/pic2.jpg'
  },
  {
    name: 'Lisa Johnson',
    position: 'Operations Director at GreenTech Solutions',
    text: 'Thanks to Soursee, we streamlined our supply chain and enhanced our product quality. Their commitment and professionalism made a real difference for us.',
    image: '/Assets/testimonial/pic3.jpg'
  }
];

function Testimonial() {
  const { data, loading, error } = useFetch("/testimonials?populate=*");
  return (
    <section className="testimonial-section">
        <div className='title-wrapper slide0'>
        <h2 className='section-title'>What Our Client Says</h2>
        <div className='separator'></div>
      </div>
      <div className="testimonial-container">
        {data.map((item, index) => (
          <div key={index} className="testimonial-card">
            <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes.img.data.attributes.url}alt={item.attributes?.title} className="testimonial-image" />
            <div className="testimonial-content">
              <p className="testimonial-text">“{item.attributes.description}”</p>
              <h3 className="testimonial-name">{item.attributes?.title}</h3>
              <p className="testimonial-position">{item.attributes?.subheading}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonial;
