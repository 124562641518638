import React from "react";
import { NavHashLink } from "react-router-hash-link";
import useFetch from "../../hooks/Usefetch";
import {BlocksRenderer} from '@strapi/blocks-react-renderer';

function Hometwosec() {
  const { data, loading, error } = useFetch("/homepage-image-and-texts?populate=*");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading content...</p>;

  if (!data || data.length === 0) {
    return <p>No content available</p>;
  }

  return (
    <section className="column-1 c-extra">
      <div className="container">
        {data.map((item, index) => {
          return (
            <div key={index} className="con">
              <div className="col-1">
                <h2 className="service-h2">
                  {item.attributes?.title}
                </h2>
                <div className="service-p">
                  <BlocksRenderer
                    content={item.attributes.desc} 
                  />
                </div>
                <NavHashLink to='/contact#form1'>
                  <button className="Btn">Quote Now</button>
                </NavHashLink>
              </div>
              <div className='col2' >
                <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes?.img1?.data?.attributes?.url} alt={item.attributes?.title} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Hometwosec;
