// src/components/ServicesSection.js
import React from 'react';
import "./servicebox.css";
import useFetch from "../../hooks/Usefetch";

const Servicesbox = () => {
  const { data, loading, error } = useFetch("/ourservices?populate=*");
  return (
    <div id="next" className="section wf-section servicesa">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title">OUR SERVICES</h2>
          <div className="separator"></div>
        </div>
        <div className="row">
          {data.map((item, index) =>(

         
          <div id={index} className="content _33 slide200">
            <div className="service-wrapper">
              <div className="icon-wrapper">
                <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes.img.data.attributes.url} loading="lazy" alt="Logistics Icon" />
              </div>
              <h3 className="service-title">{item.attributes.title}</h3>
              <p className="p15">{item.attributes.description}</p>
            </div>
          </div>
           ))}
    </div>
    </div>
    </div>
  );
};

export default Servicesbox;
